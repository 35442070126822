@import './../../VarAndMIxins.scss';

.qmark {
    width: 2.5rem;
    padding: 0.2rem;
    border: solid 1px #e5e5e5;
    border-radius: 100%;
    margin-right: 1rem;
}
.Home__bg_hello {
    // background-image: url('./../../imgs/hello.png');
    background-position: bottom right;
    background-size: cover;
}
.About__bg_therapist {
    background: #161412;
    // background-image: url('./../../imgs/nc3.jpg');
    background-image: url('./../../imgs/nc.jpg');
    background-size: cover;
    background-position: 50% 25%;
    // background-position: center;
    min-height: 60rem;
    .cover {
        background: rgba(0, 0, 0, 0.2);
    }
}

.ProfessionalBodies {
    background: #efefef;
}
.ProfessionalBodies .wrapper {
    padding-top: 12rem;
    padding-bottom: 10rem;
    // text-align: justify;
}
.ProfessionalBodies__logos {
    text-align: center;
    margin: 0 0 3rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}
.ProfessionalBodies__logo {
    height: 6rem;
    margin: 0.5rem 1rem;
}
.ProfessionalBodies__logo.bacp {
    left: -0.5rem;
}
.ProfessionalBodies__logo.ukcp {
    transform: scale(1.3);
    top: -1rem;
    left: 0.7rem;
}
.ProfessionalBodies__logo.dbs {
    display: none;
}
.Nujoji__pb_links {
    height: 4rem;
    margin: 2rem 0.2rem -1rem;
}

.qualification-num {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    border: solid 1px #e5e5e5;
}
.Nujoji .qualifications {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    margin-top: -1rem;
    // padding-left: 3.5rem;
    // text-align: left;
    // line-height: 1.5;
    // img {
    //     height: 2.7rem;
    //     margin-right: 0.7rem;
    //     // position: absolute;
    //     // left: 0rem;
    //     // top: 0rem;
    // }
}
.Nujoji .qualifications:nth-child(1) {
    margin-top: -0.7rem;
}
.tp__logo {
    position: relative;
    height: 7rem;
    float: left;
    padding: 0 1.2rem 0 0;
}

@media only screen and (max-width: 1150px) {
    .ProfessionalBodies__logo {
        height: 4.4rem;
        margin: 0.5rem 0.5rem;
    }
    .ProfessionalBodies__logo:first-child {
        margin-right: 1rem;
    }
    .ProfessionalBodies__logos {
        margin-bottom: 1.5rem;
    }
}

@media only screen and (max-width: 786px) {
    .Home__bg_hello {
        background-position: left top;
    }
}
@media only screen and (max-width: 900px) {
    .ProfessionalBodies__logo {
        height: 9vw;
        max-height: 9rem;
        margin: 1rem 0.5rem;
    }

    .ProfessionalBodies__logo.ukcp {
        top: -0.5rem;
    }
    .ProfessionalBodies__logo.emdr {
        margin-left: -1.5rem;
    }
    .tp__logo {
        height: 5rem;
        margin-top: 1rem;
    }
    .ProfessionalBodies__logo.dbs {
        display: inline-block;
    }
}
@media only screen and (max-width: 480px) {
    /* .ProfessionalBodies__logo{
        height: 3.7rem;
        margin: .5rem .5rem;
    } */
}
