@import './../../VarAndMIxins.scss';
.modal {
    background: white;
    width: 90vw;
    height: 90vh;
    overflow: hidden;
    line-height: 1.8;

    ::-webkit-scrollbar {
        width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px #e5e5e5;
        border-radius: 20px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: $HOME_COLOR;
        border-radius: 20px;
    }
}
.popup-overlay {
    background: rgba(255, 255, 255, 0.5);
}
.nc-img {
    height: 90vh;
    top: -5vh;
}
.trigger {
    font-size: 1.6rem;
}
.close {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    color: white;
    font-size: 4rem;
    z-index: 9;
    cursor: pointer;
}
.cols {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 80vh 90vh;
    padding-top: 5vh;
    column-gap: 0.4rem;
}

.text {
    overflow-y: scroll;
    padding: 5rem;
}
.modal h1 {
    margin-bottom: 2rem;
}
.modal h2 {
    font-size: 1.8rem;
    padding: 0 0 0.7rem;
    font-weight: 400;
}
.modal {
    h1,
    h2 {
        color: darken($HOME_COLOR, 20%);
    }
}
.modal.therapy {
    h1,
    h2 {
        color: darken($THERAPY_COLOR, 20%);
    }
}
.modal.contact {
    h1,
    h2 {
        color: darken($CONTACT_COLOR, 20%);
    }
}
.modal.resources {
    h1,
    h2 {
        color: darken($RESOURCES_COLOR, 20%);
    }
}
.modal.blog {
    h1,
    h2 {
        color: darken($ARTICLES_COLOR, 20%);
    }
}
@include tablet {
    .nc-img {
        display: none;
    }
    .close {
        color: #111;
        top: 1rem;
        right: 1rem;
        font-size: 3rem;
    }
}
