@import './VarAndMIxins.scss';
html {
    font-size: 62.5%;
}

body {
    font-size: 1.5rem;
    line-height: 1.2;
    font-family: $FONT_FAMILY_BODY;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    // cursor: url('./imgs/dot.png'), auto;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    position: relative;
}

li,
ol {
    list-style: none;
}

a:link,
a:visited,
a:hover,
a:active {
    color: #111;
    cursor: pointer;
    text-decoration: none;
}
a.link-underlined {
    text-decoration: underline !important;
}

.link.italics {
    font-style: italic;
}

input,
button,
select,
option {
    background-clip: padding-box;
    border: none;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
    outline: 0;
}

p {
    line-height: 1.8;
    margin: 1rem 0;
}
.nowrap {
    white-space: nowrap;
}
button {
    background: none;
}
