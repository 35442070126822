@import './../../VarAndMIxins.scss';

.loading {
    transition: all 5s;
}
.Page {
    overflow-x: hidden;
}
.Page__header {
    transition: left 1s;
    background-color: #f5f5f5;
    .wrapper {
        padding-bottom: 12rem;
    }
}
.Nav-mob {
    display: none;
}
.Nav__desktop {
    width: 100vw;
}

.Nav {
    display: flex;
}
.NavLinks {
    flex-grow: 1;
}
.NavLinks__link {
    font-size: 1.4rem;
    font-family: $FONT_FAMILY_HEADINGS;
    display: inline-block;
    padding: 6rem 1.3rem 3rem;
    text-align: center;
}
.NavLinks__link:last-child {
    width: 7rem;
}
.NavLinks__link:nth-child(2) {
    width: 7.6rem;
}
.NavLinks__link.selected {
    @include themify {
        background: $PAGE_COLOR;
    }
    transition: color 1s;
}

.Nav__hum {
    flex-grow: 1;
    cursor: pointer;
    display: none;
    left: 0;
    top: -0.1rem;
    text-align: left;
    width: 4rem;
    transition: 1s;
    span {
        display: block;
        background: #111;
        height: 0.3rem;
        margin: 0.5rem 0;
        width: 3rem;
        transition: 0.2s;
    }
}

.Nav__num {
    height: 4rem;
    font-size: 1.4rem;
    font-family: $FONT_FAMILY_HEADINGS;
    letter-spacing: 0rem;
    padding: 1rem 2rem;
    border: solid 0.1rem #111;
    top: 5rem;
    margin-right: 0.2rem;
    display: inline-block;
}

.Nav__media {
    display: inline-block;
    padding: 0;
    width: 4rem;
    height: 4rem;
    top: 5rem;
    margin: 0 0.1rem;
    border: solid 0.1rem #111;
    text-align: center;
}
.Nav__media img {
    height: 3rem;
    top: 0.3rem;
}

.Nav-mob__img {
    display: none;
    opacity: 0.2;
    position: absolute;
    top: 18%;
    right: 40%;
}

.Page.menu-open .Nav__hum span:nth-child(1) {
    transform: rotate(45deg);
    top: 0.2rem;
    width: 2rem;
    left: 1.3rem;
}
.Page.menu-open .Nav__hum span:nth-child(2) {
    left: 0;
}
.Page.menu-open .Nav__hum span:nth-child(3) {
    transform: rotate(-45deg);
    top: -0.2rem;
    width: 2rem;
    left: 1.3rem;
}
.Page__title {
    font-family: $FONT_FAMILY_HEADINGS;
    text-transform: uppercase;
    // padding-top: 12rem;
    // left: 18rem;
    span {
        display: block;
    }
    h1 {
        font-weight: normal;
        line-height: 0.95;
    }
    // h1 {
    //     font-size: 5rem;
    //     letter-spacing: 0.2rem;
    //     line-height: 1;
    //     color: #ccc;
    //     font-weight: normal;
    //     text-transform: uppercase;
    //     letter-spacing: -0.1rem;
    // }
    // span {
    //     display: block;
    //     padding-top: 10rem;
    //     padding-bottom: 0.7rem;
    //     font-size: 3.2rem;
    //     font-weight: normal;
    //     text-transform: capitalize;
    // }
    // h2 {
    //     color: #ccc;
    //     text-transform: uppercase;
    //     padding-top: 0.2rem;
    //     font-size: 1.5rem;
    // }
}
.logo {
    width: 64rem;
    padding-top: 10rem;
}
.Page__title-1 {
    font-size: 5rem;
}
.Page__title-2 {
    font-size: 5.4rem;
}
.Page__title-3 {
    font-size: 2rem;
    font-weight: bold;
    letter-spacing: 1.05rem;
    left: 0.3rem;
    padding-top: 0.2rem;
}
.Page__header-img {
    // height: 25rem;
    position: absolute;
    // right: 15rem;
    left: 3rem;
    // top: 15rem;
    top: 22rem;
    // transform: scaleX(1.2);
    // opacity: 0.2;
    height: 14rem;
}
footer {
    background-color: #f5f5f5;
    padding: 3rem 0 1rem;
    text-align: center;
    font-family: $FONT_FAMILY_HEADINGS;
    .btn {
        margin: 1rem auto 2rem;
    }

    p {
        font-size: 1rem;
    }
}
.FooterNavLinks {
    padding: 1rem 0 0.7rem;
    width: 90%;
    margin: auto;
}
.FooterNavLinks__link {
    display: inline-block;
    padding: 0.7rem;
}
.FooterNavLinks__link:hover {
    color: $COLOR_LINK;
    text-decoration: underline;
}

//NEW HEADER
.newHeader {
    min-height: 45rem;
    background-color: #eee;
    color: #212121;
    background-size: 24rem;
    background-repeat: no-repeat;
    background-position: 80% 70%;
    background-image: url('./../../imgs/dummy5.png');
    .Nav-desktop {
        background-color: rgba(255, 255, 255, 0.3);
    }

    h1.newHeader-title {
        font-family: $FONT_FAMILY_HEADINGS;
        text-transform: uppercase;
        font-size: 5.5rem;
        line-height: 1.1;
        margin-top: 10rem;
        font-weight: bolder;
        letter-spacing: 0.2rem;
        text-align: left;
    }
}

// .Page.Home .Page__header.newHeader {
//     background-image: url('./../../imgs/dummy4-min.png');
//     background-size: 23.7rem;
// }
// .Page.Therapy .Page__header.newHeader {
//     background-image: url('./../../imgs/dummy2-min.png');
// }
// .Page.Contact .Page__header.newHeader {
//     background-image: url('./../../imgs/dummy3-min.png');
// }
// .Page.Resources .Page__header.newHeader {
//     background-image: url('./../../imgs/dummy1-min.png');
// }
// .Page.Articles .Page__header.newHeader {
//     background-image: url('./../../imgs/dummy5.png');
// }

@include desktop-sm {
    .Nav {
        padding-top: 3rem;
    }
    .Nav .NavLinks {
        display: none;
    }
    .Nav__hum {
        display: block;
    }
    .Nav__hum:focus {
        div {
            display: inline-block;
            padding: 0 0.5rem;
            left: -0.5rem;
            box-shadow: 0 0 0.2rem 0.2rem green;
        }
    }
    .Nav__media,
    .Nav__num {
        top: 0rem;
    }

    .Page__header {
        width: 150vw;
        left: -50vw;
        display: grid;
    }
    .Page__title {
        // left: 15rem;
        // padding-top: 14rem;
    }
    // butterfly
    .Page__header-img {
        // right: 16%;
        left: 10rem;
        top: 20rem;
    }
    .Nav-desktop {
        left: 50vw;
        width: 100vw;
    }
    .Page.menu-open .Nav-mob {
        // display: block;
    }
    .Nav-mob {
        display: block;
        background-image: url('./../../imgs/bgGrey.png');
        width: 50vw;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    .Nav-mob .NavLinks {
        align-content: center;
        background: rgba(0, 0, 0, 0.85);
        display: grid;
        height: 100%;
        padding-left: 30%;
        width: 100%;
        z-index: 8;
    }
    .Nav-mob .NavLinks__link {
        color: #fff;
        font-family: $FONT_FAMILY_HEADINGS;
        font-size: 1.8rem;
        letter-spacing: 0.1rem;
        padding: 0.5rem 0;
        text-align: left;
        width: 80%;
    }
    .Nav-mob .NavLinks__link.selected {
        background: none;
    }
    .Nav__desktop {
        left: 50vw;
    }

    .Page.menu-open .Page__header {
        left: 0;
    }

    /* //menu bg */
    .Nav-mob__img {
        // display: block;
    }

    .newHeader {
        background-position: 90% 70%;
    }
}
@include tablet {
    /* .Page.m_open .Nav__desktop_img{
            opacity: .3;
            transition: .1s .5s;
        } */
    // .Page__title {
    //     h1 {
    //         font-size: 5rem;
    //         margin-top: 0.5rem;
    //     }
    // }
    // .Page__header-img {
    //     top: 12rem;
    //     right: 10%;
    // }
    .Page__title {
        // left: 12rem;
        // padding-top: 12rem;
    }
    // butterfly
    .Page__header-img {
        // right: 16%;
        // left: 7rem;
        top: 18rem;
    }
    .logo {
        width: 60rem;
        padding-top: 10rem;
        left: -3rem;
    }
}
@include mobile {
    .Page__header {
        width: 200vw;
        left: -100vw;
    }
    .Nav-mob {
        width: 100vw;
        left: 0;
    }
    .Nav-desktop {
        left: 100vw;
        width: 100vw;
    }
    .Page.menu-open .Nav__hum {
        left: -17rem;
        transition: 0.5s 0.5s;
        z-index: 9;
    }
    .Page.menu-open .Nav__hum span {
        background: #fff;
    }

    .Nav__media,
    .Nav__num {
        left: 2rem;
    }
    // .Page__title {
    //     h1 {
    //         font-size: 4rem;
    //         color: #111;
    //     }
    //     span {
    //         font-size: 2.8rem;
    //     }
    //     h2 {
    //         color: #111;
    //         font-size: 1.3rem;
    //     }
    // }
    // .Page__header-img {
    //     transform: scaleX(1);
    //     right: 5%;
    //     opacity: 0.2;
    //     top: 10rem;
    // }
    .Page__title {
        // padding: 12rem 0 0;
        // transform: scale(0.8);
        // left: -4%;
        // left: 1rem;
    }
    .Page__title-1 {
        font-size: 3.8rem;
    }
    .Page__title-2 {
        font-size: 4.1rem;
    }
    .Page__title-3 {
        font-size: 1.6rem;
        letter-spacing: 0.85rem;
        left: 0;
        padding-top: 0.2rem;
    }
    // butterfly
    .Page__header-img {
        display: none;
    }
    footer p {
        font-size: 0.8rem;
    }
    .logo {
        width: 40rem;
        padding-top: 10rem;
        top: 1rem;
        left: -1rem;
    }
    .newHeader {
        .Nav-desktop {
            background-color: rgba(255, 255, 255, 0.7);
        }

        h1.newHeader-title {
            font-size: 4rem;
            top: 5rem;
        }
    }
}

@media only screen and (max-width: 560px) {
}

@include mobile-sm {
    .Page.menu-open .Nav__hum {
        left: -13rem;
    }
    .Page__title {
        // padding: 15rem 0 3rem;
    }
    .Page__title-1 {
        font-size: 3.1rem;
    }
    .Page__title-2 {
        font-size: 3.35rem;
    }
    .Page__title-3 {
        font-size: 1.6rem;
        letter-spacing: 0.5rem;
    }
    // .Page__title {
    //     h1 {
    //         font-size: 3rem;
    //         letter-spacing: -0.1rem;
    //         line-height: 1.1;
    //     }
    //     span {
    //         padding-top: 20rem;
    //         font-size: 2.4rem;
    //         letter-spacing: 0;
    //     }
    // }
    // .Page__header-img {
    //     transform: scaleX(-1);
    //     right: 10%;
    //     height: 20rem;
    //     top: 12rem;
    // }
    .logo {
        width: 100%;
        padding-top: 10rem;
        top: 1rem;
        left: -1rem;
    }
    .newHeader {
        .Nav-desktop {
            background-color: rgba(255, 255, 255, 0.7);
        }

        h1.newHeader-title {
            font-size: 3rem;
            top: 7rem;
        }
    }
}
