@import '../../VarAndMIxins.scss';

.question-mark {
    width: 9rem;
    margin: auto;
}
.addresses {
    margin: 1rem 0 2rem;
}
.address {
    text-align: left;

    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    margin: 0.5rem 0;
    img {
        height: 2.7rem;
        margin-right: 1rem;
        display: inline-block;
    }
}
#contact-form .section__title {
    text-align: center;
}
.Form .link {
    // text-decoration: underline;
}
.Office__bg_img {
    background: #111;
    background-image: url('./../../imgs/room-geo.jpeg');
    background-size: cover;
    background-position: center;
    min-height: 50rem;
}

.Office .cover {
    /* padding: 0 15rem 5rem 0; */
    // padding-left: 20%;
}
.Directions__bg_lnd {
    // background-image: url('./../../imgs/lnd.png');
    background-position: right center;
    background-repeat: no-repeat;
}
.Directions__img {
    width: 35rem;
    margin: 1rem 0 1rem 2rem;
}
.Directions__bg_map {
    background: #fff;
    background-image: url('./../../imgs/nc-bw.jpg');
    background-size: cover;
    background-position: center 20%;
    min-height: 50rem;
}
.Office .cover {
    align-content: center;
    background: none;
    /* background: rgba(255,255,255,.5); */
    // background: rgba(255, 255, 255, 0.6);

    color: #111;
    font-size: 1.8rem;
    text-align: center;
    padding: 0 20%;
    transition: 0.5s;
}
.Office .cover small {
    display: block;
    font-size: 1rem;
}
.Office .cover p {
    /* opacity: 0;
    top: 15rem; */
    transition: 0.75s 0.1s;
}
.Office .cover:hover p {
    opacity: 1;
    top: 0;
    transition: 0.5s;
}
.Office .btn {
    font-size: 1.1rem;
    /* opacity: 0;
    top: 5rem; */
    margin: 2rem auto 0;
    transition: 0.5s;
    transition: 0.25s;
    width: 22rem;
}
.Directions .cover:hover .btn {
    // opacity: 1;
    // top: 0;
    // transition: 0.5s;
}

.Office .cover:hover {
    // background: rgba(255, 255, 255, 0.8);
    // transition: 0.2s;
}

.Contact__form {
    width: 32rem;
    margin: auto;
    // padding-top: 4rem;
    font-size: 1.3rem;
    // font-family: $FONT_FAMILY_HEADINGS;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        //to get rid off bg color
        -webkit-transition-delay: 99999s;
    }
    label {
        padding: 0.5rem;
        display: block;
        height: 0.1rem;
        line-height: 1.2;
        img {
            width: 1.8rem;
            top: 1.7rem;
            left: 0.5rem;
            opacity: 0.8;
        }
        b {
            top: 0.5rem;
            left: 0.3rem;
            font-weight: normal;
        }
    }
    input {
        display: block;
        border: solid 0.1rem #111;
        font-size: 1.4rem;
        background: none;
        // border-bottom: solid 0.1rem #111;
        width: 100%;
        // padding: 2.5rem 5rem 1.5rem;
        padding: 1rem 1rem;
        padding-left: 3.7rem;
        font-family: $FONT_FAMILY_BODY;
    }
    fieldset {
        display: block;
        border: none;
        margin-top: 1rem;
        padding-left: 1rem;
    }
    legend {
        font-size: 1.4rem;
        text-align: left;
        display: block;
        margin: 0 0 0.4rem;
        img {
            height: 2.2rem;
            margin: 0 0.7rem 0 0.4rem;
            top: 0.6rem;
        }
    }
}
.Contact__form input:focus {
}
.Contact__form button.btn {
    font-size: 1.3rem;
    font-weight: 400;
    margin: 3rem auto 1rem;
    /* border-radius: 0; */
    /* border-width: .1rem; */
}

#mail2 {
    display: none;
}
.Form__locations {
    margin-top: 1.5rem;
    padding: 0.5rem;
}
.Form__locations p {
    text-align: left;
}
.RadioInput {
    margin: 0;
}
.RadioInput__input {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
}
.RadioInput__fake {
    display: grid;
    grid-template-columns: auto 1fr;
    margin: 0.3rem 0;
}
.RadioInput__fake.clicked {
    opacity: 1;
    color: #111;
}
.RadioInput__box {
    width: 2rem;
    height: 2rem;
    border: solid 0.1rem #111;
    margin-right: 1rem;
    // opacity: 0.6;
}
.RadioInput__box_check {
    font-size: 2.5rem;
    left: 0.2rem;
    top: -0.85rem;
    opacity: 0;
}
.RadioInput__fake.clicked .RadioInput__box_check {
    opacity: 1;
    color: #111;
}
.RadioInput__fake.clicked .RadioInput__box {
    opacity: 1;
    border-color: #111;
}
.RadioInput__text {
    text-align: left;
}
.Form__err {
    font-size: 1.2rem;
    height: 1rem;
    margin-top: 1.5rem;
    p {
        line-height: 1;
    }
    /* opacity: .4; */
}
.Form__message_sent {
    font-size: 1.4rem;
    b {
        display: block;
        margin-bottom: 1rem;
        font-size: 3rem;
        font-family: $FONT_FAMILY_HEADINGS;
    }
    img {
        width: 15rem;
        margin: auto;
        opacity: 0.1;
        margin-bottom: 2rem;
        left: -2rem;
    }
    .btn {
        margin: 4rem auto 2rem;
    }
}
.Form__message_sent,
.Form__message_err {
    opacity: 0;
    transform: scale(0.5);
    animation: slideIn 0.5s 0.5s cubic-bezier(0.1, 1.13, 0.7, 1.79) forwards;
}
.text_side {
    font-size: 1rem;
    margin-top: 1rem;
    line-height: 1.2;
    // width: 40rem;
    // position: absolute;
    // text-align: center;
    // transform: rotate(90deg) translateY(-50%);
    // top: 48%;
    // right: -12rem;
    // opacity: 0.5;
}
@keyframes slideIn {
    to {
        opacity: 1;
        transform: scale(1);
    }
}
.Form__message_err span {
    background: rgba(255, 255, 255, 1);
    border-radius: 100%;
    color: $CONTACT_COLOR;
    display: inline-block;
    font-size: 1.6rem;
    line-height: 2.5rem;
    font-weight: bolder;
    height: 2.5rem;
    margin-right: 0.5rem;
    text-align: center;
    width: 2.5rem;
    top: 0.2rem;
}
.Contact__form.shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(4px, 0, 0);
    }
}
:not(output):-moz-ui-invalid {
    box-shadow: none;
}
@media only screen and (max-width: 1200px) {
    .Directions .cover {
        background: rgba(255, 255, 255, 0.8);
        transition: 0.2s;
    }
    .Directions .cover p {
        opacity: 1;
        top: 0;
        transition: 0.5s;
    }
    .Directions .cover .btn {
        opacity: 1;
        top: 0;
        transition: 0.5s 0.25s;
    }
}
@media only screen and (max-width: 1024px) {
    .Office,
    .Directions {
        display: block;
    }
    .Office .cover {
        /* padding: 0 5rem 5rem 0; */
        // padding-left: 15%;
    }

    .Contact__form {
        padding-top: 0;
        padding-right: 1rem;
    }
    // .Contact__form .btn {
    //     margin-bottom: -2rem;
    // }
    // .Form__err {
    //     top: 5rem;
    // }
    .Office__bg_img,
    .Directions__bg_map {
        min-height: 70rem;
        background-position: 50% 20%;
    }
    .Office__bg_img {
        display: block;
        width: 100vw;
    }
    .Office {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
    }
    .Office .Contact__bg_themed {
        -webkit-flex-order: -1;
        order: -1;
        // background: #fff;
        // background-color: #fff;
    }
    .Directions .cover {
        // background-image: linear-gradient(
        //     rgba(255, 255, 255, 0.3),
        //     rgba(255, 255, 255, 0.3),
        //     $CONTACT_COLOR
        // );
    }
    .Form {
        background-color: #f5f5f5;
    }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
    .Office__bg_img,
    .Directions__bg_map {
        height: 50rem;
    }
    .Office .Contact__bg_themed {
        // background: #fff !important;
        // background-color: #fff !important;
    }
    .Office {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
    }
    .Office .Contact__bg_themed {
        -webkit-flex-order: -1;
        order: -1;
        // background: #fff;
        // background-color: #fff;
    }
}
@media only screen and (max-width: 786px) {
    // .text_side {
    //     top: 45%;
    //     right: -16rem;
    // }
}
@media only screen and (max-width: 480px) {
    .Contact__form {
        width: 100%;
    }
    .Directions__img {
        width: 25rem;
        margin: 1rem 0 2rem;
    }
    // .text_side {
    //     right: -16.5rem;
    // }
    .Form {
        // left: -1rem;
    }

    .Office .cover {
        /* padding: 0 5rem 5rem 0; */
        // padding-left: 10%;
    }
}
