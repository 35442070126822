@import './../../VarAndMIxins.scss';

.card {
    background: #fff;
    padding: 5rem;
    width: 90rem;
    margin: 3rem auto;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    .card__desc {
        -webkit-flex-grow: 1;
        flex-grow: 1;
    }
    .btn {
        margin-top: 2rem;
    }
}

.card__logo img {
    max-width: 20rem;
    margin-left: 4rem;
}
.card__title {
    font-family: $FONT_FAMILY_HEADINGS;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2.2rem;
    margin-bottom: 0.3rem;
    line-height: 1.4;
}
.card__subtitle {
    font-family: $FONT_FAMILY_HEADINGS;
    text-align: left;
    // text-transform: uppercase;
    font-weight: bold;
    font-size: 1.4rem;
    margin-bottom: 2rem;
}
.card__summary {
    line-height: 1.5;
}
@include tablet {
    .card {
        width: 80%;
        flex-direction: column-reverse;
        align-items: flex-start;
    }

    .card__logo {
        img {
            margin: 0 0 2rem;
        }
    }
}
@include mobile {
    .cards {
        padding: 10rem 0;
    }
    .card {
        width: 85%;
        padding: 6.7rem 3rem 4.4rem;
    }
}
@include mobile-sm {
    .card__title {
        width: 90%;
    }
}
