@import '../../VarAndMIxins.scss';
.ul-qualifications {
    li {
        padding: 0.7rem 0;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        span {
            font-size: 14px;
            line-height: 1.8;
        }
    }
}
.qm {
    width: 2rem;
    height: 2rem;
    border: solid 1px #efefef;
    border-radius: 100%;
    margin-right: 1rem;
    top: 0.5rem;
}
.TherapyIntro__img {
    margin: auto;
    margin-bottom: -5rem;
    /* margin-top: -2rem; */
    width: 115%;
}
.IntegrativeTherapy__bg_respect {
    // background-image: url('./../../imgs/respect_darker.png');
    background-position: right center;
    background-size: cover;
}

.FAQ .wrapper {
    padding-top: 10rem;
    padding-bottom: 10rem;
}
.fr1auto {
    align-items: center;
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 1fr auto;
}

.Accordion {
    margin-top: 4rem;
    margin-bottom: 2rem;
}
.accordion__li {
    max-height: 9.5rem;
    margin-bottom: 0.5rem;
    overflow: hidden;
    transition: 0.5s;
}
.accordion__li.active {
    max-height: 300vh;
    transition: 0.75s ease-in;
}
.accordion__li span {
    font-size: 2rem;
}
.accordion__q {
    background: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    padding: 3rem 5rem 2rem;
    margin-bottom: 0.5rem;
    transition: 0.2s 0.5s;
    z-index: 2;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}
.accordion__q-text {
    -webkit-flex-grow: 1;
    flex-grow: 1;
    padding: 1rem 0 2rem;
    padding-right: 1rem;
    line-height: 1.8;
}
.accordion__a {
    background: rgba(255, 255, 255, 0.8);
    opacity: 0;
    padding: 2rem 7rem 2rem;
    // text-align: justify;
    transition: opacity 0s 0.5s;
    // margin-bottom: 2rem;
    p {
        margin: 1rem 0;
        line-height: 1.8;
    }
}

.accordion__li.active .accordion__q {
    background: $THERAPY_COLOR;
    font-weight: bold;
    transition: 0.2s;
}
.accordion__li.active .accordion__a {
    opacity: 1;
    transition: opacity 0s 0s;
}
.Insurance {
    background: #efefef;
}
.Insurance .wrapper {
    padding-top: 12rem;
    padding-bottom: 10rem;
    // text-align: justify;
}
.Insurance__logos {
    text-align: center;
    margin: 0 0 2rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}
.Insurance__logo {
    height: 5rem;
    margin: 0.5rem 0.8rem;
}

.bullet-list {
    padding-top: 2rem;
    ul {
        padding: 1rem;
    }
    li {
        padding: 0.5rem 0;
        line-height: 1.8;
    }
}
.bullet-list-banner {
    padding: 2rem 3rem;
    background: #f5f5f5;
    display: block;
    b {
        font-size: 1.8rem;
    }
}

@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1024px) {
    .TherapyIntro__img {
        width: 80%;
        max-width: 40rem;
    }
    .Emdr .Therapy__bg_themed,
    .IntegrativeTherapy .Therapy__bg_themed {
        background: #fff;
    }
    .Emdr,
    .IntegrativeTherapy .section__divider.white,
    .IntegrativeTherapy__bg_respect {
        background: $THERAPY_COLOR;
    }

    .Insurance__logos {
        width: 57rem;
        margin: auto;
    }
    .accordion__q {
        padding: 3rem 3rem 2rem 4rem;
        font-size: 13px;
    }
}
@media only screen and (max-width: 786px) {
    .fr1auto {
        grid-template-columns: 1fr;
    }
    .accordion__li {
        max-height: 6rem;

        /* margin-top: -1.5rem; */
    }
    .accordion__q {
        align-items: start;
        background: none;
        /* grid-column-gap: 1rem; */
        grid-template-columns: 1fr 1.5rem;
        padding: 0;
        font-size: 1.6rem;
    }
    .accordion__li.active .accordion__q {
        background: none;
        color: darken($THERAPY_COLOR, 20%);
        font-weight: bold;
        transition: 0s;
    }
    .accordion__li span {
        /* display: none; */
        padding-top: 1rem;
    }
    .accordion__a {
        background: none;
        font-size: 1.4rem;
        padding: 0;
    }
    .Insurance__logos {
        width: 100%;
    }
    .Insurance__logo {
        height: 7.5vw;
        max-height: 6rem;
    }
    .bullet-list-banner {
        background-color: #fff;
    }
}
@media only screen and (max-width: 480px) {
    .Insurance__logo {
        height: 10vw;
        max-height: 4rem;
    }
    .Insurance__logos a:nth-child(5) {
        order: -1;
    }
}
