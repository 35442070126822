@import '../../VarAndMIxins.scss';
.Article {
    // text-align: justify;
}

.Articles-intro {
    // text-align: left;
    img {
        margin: 2rem 0;
        width: 30rem;
        display: block;
    }
}
.Article-header {
    display: grid;
    grid-auto-rows: minmax(40vw, 1fr);
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
}
.Article-header-info {
    background: $ARTICLES_COLOR;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 15% 0 20%;
    font-size: 1.4rem;
}

.Article-header-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .cover {
        background-color: rgba(255, 255, 255, 0.3);
    }
}

.Article-header-img.odd {
    order: -1;
}

.Article-content {
    padding: 10rem 10% 8rem;
    display: none;
    background-color: #fff;
}
.Article-footer {
    text-align: center;
    padding-top: 1rem;
    margin-top: 5rem;
    border-top: solid 0.1rem #efefef;

    p {
        color: #afafaf;
        .link {
            color: #afafaf;
            text-decoration: underline;
        }
    }
    button {
        margin: 4rem auto 0;
    }
}

.Article.open {
    margin: 0 5rem;
    .cover {
        background-color: rgba(220, 220, 220, 0.7);
    }
    .Article-header {
        grid-template-columns: 1fr;
    }
    .Article-header-info {
        position: absolute;
        background-color: transparent;
        z-index: 2;
        height: 100%;
        padding-left: 10%;
        max-width: 80%;
        padding-top: 10rem;
        button {
            display: none;
        }
    }
    .Article-header-img {
        transform: scale(2);
        animation: zoom 1s forwards;
    }

    @keyframes zoom {
        to {
            transform: scale(1);
        }
    }

    .Article-content {
        display: block;
    }
}

.article-title {
    font-family: $FONT_FAMILY_HEADINGS;
    font-size: 3rem;
    line-height: 1.2;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: -0.7rem;
}
.article-subtitle {
    font-size: 2rem;
    line-height: 1.4;
    font-weight: 600;
    margin: 2rem 0;
}
.article-subtitle-small {
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: 600;
    margin: 2rem 0;
}
.article-author {
    // font-style: italic;
}
.article-summary {
    font-size: 1.6rem;
    font-weight: 100;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: -2rem;
}

.Articles-others {
    background-color: #efefef;
    padding-bottom: 5rem;
    border-bottom: solid 5px #f5f5f5;

    .btn.btn-center {
        margin: auto;
    }
}

.OtherArticle {
    .Article-post {
        display: none;
    }
    .Article-card {
        display: flex;
        padding: 4rem;
    }
    // &:first-child {
    //     padding-top: 10rem;
    // }
    // &:first-child.open {
    //     padding-top: 0rem;
    //     padding-bottom: 7rem;
    // }
    // &:last-child {
    //     padding-bottom: 10rem;
    // }
    // &:last-child.open {
    //     padding-bottom: 0rem;
    //     padding-top: 7rem;
    // }
    // &:not(:first-child):not(:last-child).open {
    //     padding-top: 7rem;
    //     padding-bottom: 7rem;
    // }
}
.OtherArticle.open {
    .Article-post {
        display: block;
    }
    .card {
        display: none;
    }
}
.Articles-category {
    width: 90rem;
    margin: auto;
    font-size: 2.4rem;
    text-transform: uppercase;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    color: #333;
    padding: 7rem 0 2rem;
    h3 {
        display: flex;
        align-items: center;
    }
    .budge {
        width: 4rem;
        height: 4rem;
        border-radius: 50%;
        background: $ARTICLES_COLOR;
        color: darken($ARTICLES_COLOR, 40%);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 1.5rem;
        font-size: 2rem;
    }
    .btn_square {
        margin: 0;
    }
    a.btn {
        margin-top: 0 !important;
    }
}

//BACKGROUND IMG FOR ARTICLES
.ConversationAboutChildSexualAbuse .Article-header-img,
.AbuseKeptSecret .Article-header-img {
    background-position: center 20%;
}
.ListeningToTheVoicesOfSurvivors .Article-header-img {
    background-position: center;
}

.ChildAbuseInquary .Article-header-img {
    background-position: top;
}

@include tablet {
    .Article-header {
        display: grid;
        grid-auto-rows: minmax(40rem, auto);
        grid-template-columns: 1fr;
        overflow: hidden;
    }
    .Article-header-info {
        padding: 10rem 10%;
        z-index: 2;
    }

    .Article-header-img {
        order: -1;
    }
    .Article.open {
        .Article-header-info {
            max-width: 100%;
        }
    }
    .Articles-category {
        width: 80%;
    }
}
@include mobile {
    .Article.open {
        margin: 0 2%;
    }
    .Articles-category {
        width: 85%;
        // flex-direction: column;
        display: grid;
        grid-template-columns: 1fr;

        h3 {
            width: 100%;
            flex-grow: 1;
            justify-content: space-between;
        }

        b {
            width: calc(100% - 6rem);
        }
        .btn_square {
            margin-top: 1rem;
        }
        .Articles-category-header {
            display: flex;
            align-items: space-between;
        }

        .Articles-category-cta {
            display: none;
        }
    }
}
