$FONT_FAMILY_BODY: 'Heebo', sans-serif;
$FONT_FAMILY_HEADINGS: 'Heebo', serif;

$ON_FOCUS_COLOR: orange;
$BG_COLOR: #f9f9f9;
$HOME_COLOR: #bcd4de;
$THERAPY_COLOR: #a5ccd1;
$CONTACT_COLOR: #a0b9bf;
$RESOURCES_COLOR: #9dacb2;
$ARTICLES_COLOR: #949ba0;
// $HOME_COLOR: #bad9df;
// $THERAPY_COLOR: #efe09e;
// $CONTACT_COLOR: #d5e0e3;
// $RESOURCES_COLOR: #f6d2cb;
// $ARTICLES_COLOR: #deecdd;

$COLOR_LINK: #1177d1;

$PAGE_COLOR: null;

$themes: (
    'Home': (
        'PAGE_COLOR': $HOME_COLOR,
    ),
    'Therapy': (
        'PAGE_COLOR': $THERAPY_COLOR,
    ),
    'Contact': (
        'PAGE_COLOR': $CONTACT_COLOR,
    ),
    'Resources': (
        'PAGE_COLOR': $RESOURCES_COLOR,
    ),
    'Articles': (
        'PAGE_COLOR': $ARTICLES_COLOR,
    ),
) !default;

@mixin themify($themes: $themes) {
    // Iterate over the themes
    @each $theme, $colors in $themes {
        .#{$theme} & {
            // Set the theme variables with `!global`
            $PAGE_COLOR: map-get($colors, 'PAGE_COLOR') !global;
            // Output user content
            @content;
            // Unset the theme variables with `!global`
            $PAGE_COLOR: null !global;
        }
    }
}

// .theme-color_transitioned{
//     @include themify {
//       color: $color-primary;
//     }
//     transition: color 1s ;
// }

@mixin desktop-sm {
    @media only screen and (max-width: 1200px) {
        @content;
    }
}
@mixin tablet {
    @media only screen and (max-width: 1024px) {
        @content;
    }
}
@mixin mobile {
    @media only screen and (max-width: 786px) {
        @content;
    }
}
@mixin mobile-sm {
    @media only screen and (max-width: 480px) {
        @content;
    }
}
@mixin portrait {
    @media only screen and (max-width: 786px) and (orientation: portrait) {
        @content;
    }
}
@mixin landscape {
    @media only screen and (max-width: 786px) and (orientation: landscape) {
        @content;
    }
}
