@import './VarAndMIxins.scss';

//==========================
//  SCREEN READERS STYLING
//==========================
.sr-only {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip: rect(1px 1px 1px 1px); //IE6 and IE7
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.sr-only--in-focus {
    position: static;
    clip: auto !important;
    clip-path: none;
    height: auto;
    margin: 0;
    overflow: auto;
    line-height: normal;
    width: auto;
    padding: 3rem 5rem;
    left: 0;
    right: 0;
    background: $ON_FOCUS_COLOR;
    color: #000 !important;
    font-size: 1.6rem;
    z-index: 1000;
    display: block;
}
.sr-only--in-focus:hover {
    text-decoration-line: underline;
}
//==========================
//  FONTS AND HEADINGS STYLING
//==========================
.font__themed {
    font-family: $FONT_FAMILY_HEADINGS;
}
.font__heading {
    font-family: $FONT_FAMILY_HEADINGS;
}

.themed-font {
    font-family: $FONT_FAMILY_HEADINGS;
    text-transform: uppercase;
}
.cover {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
}

a.link {
    color: $COLOR_LINK;
}
a.link:hover {
    text-decoration: underline;
}

//OLD STYLING

.text_cntr {
    text-align: center;
}
.text_r {
    text-align: right;
}

.heading {
    font-family: $FONT_FAMILY_HEADINGS;
}
.cover {
    align-content: end;
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    display: flex;
    display: grid;
    height: 100%;
    min-height: 40rem;
    left: 0;
    padding: 0 0 5% 10%;
    position: absolute;
    top: 0;
    width: 100%;
}
.cover_ctr {
    display: inline-block;
    margin: auto;
    text-align: center;
}
.wrapper {
    width: 100rem;
    margin: auto;
    // padding-bottom: 23rem;
}
a.btn,
.btn {
    display: block;
    border: solid 0.1rem #161412;
    color: #161412;
    cursor: pointer;
    font-size: 1.4rem;
    padding: 1.4rem 0;
    text-align: center;
    width: 20rem;
    margin-top: 4rem;
    font-family: $FONT_FAMILY_HEADINGS;
}
.btn_square:hover,
.btn:hover {
    background: #161412;
    color: #fff;
}
.btn_square {
    font-family: $FONT_FAMILY_HEADINGS;
    display: inline-block;
    border: solid 0.1rem #111;
    cursor: pointer;
    font-size: 1.2rem;
    margin: 1rem auto;
    padding: 1rem 0;
    text-align: center;
    width: 16rem;
}

section {
    display: grid;
    grid-auto-rows: minmax(40vw, 1fr);
    grid-template-columns: 50vw 50vw;
}
section div {
    align-content: center;
    display: grid;

    // text-align: justify;
}
.section__wrapper {
    width: 60%;
    padding: 10rem 0;
    margin: auto;
}
.section__title {
    font-family: $FONT_FAMILY_HEADINGS;
    font-size: 3rem;
    line-height: 1.3;
    font-weight: 100;
    // font-weight: bold;
    margin-bottom: 4rem;
    text-align: left;
    text-transform: uppercase;
    padding-top: 3rem;
}
.section__subtitle {
    /* border-bottom: solid .1rem #111; */
    font-size: 1.4rem;
    letter-spacing: 0.1rem;
    text-transform: none;
    display: block;
    font-family: $FONT_FAMILY_HEADINGS;
    font-weight: bold;
    margin-top: -3rem;
    letter-spacing: 0rem;
    text-align: left;
    // margin: 1rem 0 2rem;
}

.section__clm2 {
    display: grid;
    grid-template-columns: auto 1fr;
    margin-bottom: 2rem;
    grid-column-gap: 4vw;
}
.section__clm2 b {
    font-weight: 400;
}

.section__bg_grey,
.Page__bg_grey {
    // background-image: url('./imgs/bgGrey.png');
    background-color: #f5f5f5;
}
.Page__bg_blue {
    background-image: url('./imgs/bgBlue.png');
}

.Home__bg_themed {
    background: $HOME_COLOR;
}
.Home__color_themed {
    color: $HOME_COLOR;
}
.Therapy__bg_themed {
    background: $THERAPY_COLOR;
}
.Therapy__color_themed {
    color: darken($THERAPY_COLOR, 10%);
}
.Contact__bg_themed {
    background: $CONTACT_COLOR;
}
.Contact__color_themed {
    color: $CONTACT_COLOR;
}
.Resources__bg_themed {
    background: $RESOURCES_COLOR;
}
.Resources__color_themed {
    color: darken($RESOURCES_COLOR, 10%);
}
.Articles__bg_themed {
    background: $ARTICLES_COLOR;
}
.Articles__color_themed {
    color: darken($ARTICLES_COLOR, 10%);
}

@include desktop-sm {
    .wrapper {
        width: 100%;
        padding: 0 10rem 23rem;
    }
}

@include tablet {
    .section__wrapper {
        width: 100%;
        padding: 12rem 10rem;
    }
    section {
        grid-auto-rows: auto;
        grid-template-columns: 1fr;
        min-height: 30rem;
    }
    .section__reverse {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        .Resources__bg_themed {
            -webkit-flex-order: 1;
            order: 1;
        }
    }
    // .Contact section.Office {
    //     grid-auto-rows: 50rem 1fr;
    // }
    // .Contact section.Directions {
    //     grid-auto-rows: 1fr 60rem;
    // }
    .cover {
        padding-bottom: 3rem;
    }
    .section__clm2 {
        grid-template-columns: 1fr 1fr;
        text-align: center;
    }
    .section__clm2 p {
        border: solid 0.1rem #111;
        margin-bottom: 1rem;
        padding: 2rem 1rem;
    }
}

@include mobile {
    .mob-hidden {
        display: none;
    }
    .wrapper {
        padding: 0 5rem 23rem;
    }
    .section__wrapper {
        padding-right: 5rem;
        padding-left: 5rem;
    }

    .section__clm2 {
        grid-column-gap: 1rem;
    }
    .text_r {
        text-align: left;
    }
    body,
    .accordion__li span {
        font-size: 1.6rem;
    }
}
@include mobile-sm {
    .wrapper {
        padding: 0 3rem 23rem;
    }
    .section__wrapper {
        padding-right: 3rem;
        padding-left: 3rem;
    }
    .section__clm2 {
        display: block;
        /* padding-left: 4rem; */
        text-align: center;
    }

    .section__title {
        font-size: 2.8rem;
    }
}
//IPAD LANDSCAPE ISSUE:
//flexbox order not working
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
    .section__reverse {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        .Resources__bg_themed {
            -webkit-flex-order: 1;
            order: 1;
        }
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    .section__reverse {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        .Resources__bg_themed {
            -webkit-flex-order: 1;
            order: 1;
        }
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .section__reverse {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        .Resources__bg_themed {
            -webkit-flex-order: 1;
            order: 1;
        }
    }
}
@media screen and (max-width: 1024px) and (orientation: landscape) {
    .section__reverse {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        .Resources__bg_themed {
            -webkit-flex-order: 1;
            order: 1;
        }
    }
}
