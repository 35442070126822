@import '../../VarAndMIxins.scss';
.Intro {
    .btn {
        margin-top: 0;
    }
}
.books {
    background-color: #add9b6;
}
.chair {
    display: gird;
    grid-template-columns: 1fr auto;
    align-items: start;

    img {
        height: 15rem;
        padding: 0 2rem;
        margin-bottom: -3rem;
        top: -1rem;
    }
}

.resources__img {
    max-height: 30rem;
    box-shadow: 0 0 1rem 0.2rem #eee;
    margin-right: 4rem;
}
.resources__img--no-shadow {
    box-shadow: none;
}
.resources__img--border {
    border: solid 0.1rem #111;
}
.books-inline-watermark {
    display: none;
    position: absolute;
    width: 80%;
    max-width: 60rem;
    min-width: 40rem;
    top: 20%;
    right: 0%;
    opacity: 0.5;
}
.podcasts-inline-watermark {
    display: none;
    position: absolute;
    bottom: 5rem;
    right: -10rem;
    height: 17rem;
    opacity: 0.8;
}
.videos {
    background-color: #f5f5f5;
    display: block;
    padding: 10rem 0;
    line-height: 1.8;
}

@include tablet {
    .resources__title .section__wrapper {
        background-color: #f5f5f5;
    }
    .podcasts .resources__title .section__wrapper {
        background-color: #fff;
    }
    .resources__title .section__body {
        display: grid;
        grid-template-columns: auto 1fr;
    }
    .resources__img {
        max-width: 18rem;
        max-height: 30rem;
    }
}
@include mobile {
}
//diferent media styling because of long word :PSYCHOLOGY
@media only screen and (max-width: 524px) {
    .podcasts-inline-watermark,
    .books-inline-watermark {
        display: none;
    }
    .resources__img {
        max-width: 25rem;
        max-height: 30rem;
    }
    .resources__title .section__body {
        display: block;
    }
    .chair img {
        top: 1rem;
    }
}
@include mobile-sm {
}

@media only screen and (max-width: 400px) {
    .chair {
        grid-template-columns: 1fr;
    }
}
